<template>
  <div class="bg-black text-white pb-8 pt-4">
    <div class="lg:container pb-4 sm:py-8 px-4">
      <div class="flex">
        <h1 class="text-xl font-bold">{{ props.deck.title }}</h1>
        <div class="ml-auto space-x-3 hidden lg:block">
          <button class="prev bg-white rounded-full p-3" ref="prevButton">
            <img src="@/icons/chevron/left.svg" alt="Previous">
          </button>

          <button class="next bg-white rounded-full p-3" ref="nextButton">
            <img src="@/icons/chevron/right.svg" alt="Next">
          </button>
        </div>
      </div>
    </div>

    <swiper
      loop
      :modules="[Navigation]"
      :navigation="{ nextEl: nextButton, prevEl: prevButton }"
      :breakpoints="breakpoints"
      :slides-per-view="1"
    >
      <swiper-slide v-for="slide in props.deck.items" :key="slide" class="h-[auto]">
        <item-card :item="slide" :type="props.deck.type" class="px-4 h-[100%]"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
  import { ref } from 'vue'

  import { Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import 'swiper/css'

  import ItemCard from '@/components/ItemCard'

  const props = defineProps({
    deck: Object
  })

  const prevButton = ref(null)
  const nextButton = ref(null)

  const breakpoints = {
    1024: {
      slidesPerView:5.5
    },

    0: {
      slidesPerView: 2.5
    }
  }
</script>