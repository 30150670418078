<template>
  <div class="bg-black text-white">
    <div class="lg:container lg:mx-auto px-4 py-8">
      <div class="text-center">
        <h1 class="text-[8rem] font-thin">404</h1>
        <h2 class="text-[2rem]">Not Found</h2>
  
        <router-link class="inline-block bg-grey-light rounded-xl p-3 my-8 text-black" :to="{ name: 'home' }">Go Home</router-link>
      </div>
    </div>
  </div>
</template>