<template>
  <nav class="bg-white">
    <div class="lg:container lg:mx-auto p-4">
      <div class="relative flex items-center">
        <router-link :to="{ name: 'home' }" class="flex font-semibold items-center ">
          <img src="@/icons/logo.svg" alt="Club Global Logo" class="w-12 mr-4">
          Club Global
        </router-link>

        <div class="lg:ml-24 text-primary font-medium space-x-4 hidden lg:flex">
          <router-link :to="{ name: 'home' }" class="p-2" active-class="text-grey-dark">Home</router-link>
          <router-link :to="{ name: 'map' }" class="p-2" active-class="text-grey-dark">Map</router-link>
          <router-link :to="{ name: 'events' }" class="p-2" active-class="text-grey-dark">Events</router-link>
          <router-link :to="{ name: 'locations' }" class="p-2" active-class="text-grey-dark">Locations</router-link>
          <router-link :to="{ name: 'search' }" class="p-2" active-class="text-grey-dark">Search</router-link>
          <a :href="newsLink" class="p-2">News</a>
        </div>

        <div class="ml-auto font-medium space-x-4 hidden lg:flex">
          <a :href="businessLink" class="bg-grey-light rounded-xl p-3">For Business</a>
          <a :href="userStore.isAuthenticated() ? baseUrl +'reps?authuser=' + userStore.token : baseUrl + 'reps'"  class="bg-grey-darker text-white rounded-xl p-3">For Reps</a>

          <router-link :to="{ name: 'login' }" v-if="!userStore.isAuthenticated()" class="bg-gradient-to-r from-primary to-secondary text-white rounded-xl p-3">Sign In/Register</router-link>
          <router-link :to="{ name: 'profile' }" v-else class="flex items-center font-normal space-x-2 ml-auto border border-dark-grey rounded-xl p-3">
            <span>{{ userStore.name }}</span>
            <img src="@/icons/profile.svg" alt="Profile">
          </router-link>
        </div>

        <button class="lg:hidden flex items-center space-x-2 ml-auto border border-dark-grey rounded-xl p-4" @click.prevent="mobileOpen = !mobileOpen">
          <img src="@/icons/hamburger.svg" alt="Menu">
          <img src="@/icons/profile.svg" alt="Profile" v-if="userStore.isAuthenticated()">
        </button>
      </div>

      <div v-if="mobileOpen" class="lg:hidden flex flex-col space-y-2 mt-4">
        <router-link :to="{ name: 'home' }" class="p-2" active-class="text-grey-dark">Home</router-link>
        <router-link :to="{ name: 'map' }" class="p-2" active-class="text-grey-dark">Map</router-link>
        <router-link :to="{ name: 'events' }" class="p-2" active-class="text-grey-dark">Events</router-link>
        <router-link :to="{ name: 'locations' }" class="p-2" active-class="text-grey-dark">Locations</router-link>
        <router-link :to="{ name: 'search' }" class="p-2" active-class="text-grey-dark">Search</router-link>
        <a :href="newsLink" class="p-2">News</a>
        <a :href="businessLink" class="bg-grey-light rounded-xl p-4">For Business</a>
        <a :href="userStore.isAuthenticated() ? baseUrl +'reps?authuser=' + userStore.token : baseUrl + 'reps'" class="bg-grey-darker text-white rounded-xl p-4">For Reps</a>

        <router-link :to="{ name: 'login' }" v-if="!userStore.isAuthenticated()" class="bg-gradient-to-r from-primary to-secondary text-white rounded-xl p-4">Sign In/Register</router-link>
        <router-link :to="{ name: 'profile' }" v-else class="bg-gradient-to-r from-secondary to-primary text-white rounded-xl p-4">Profile</router-link>
      </div>
    </div>
  </nav>
</template>

<script setup>
  import { ref } from 'vue'
  import { useUserStore } from '@/store/user'
  import { useRouter } from 'vue-router'

  const userStore = useUserStore()
  const router = useRouter()
  const baseUrl = process.env.VUE_APP_API_BASE_URL;

  const businessLink = process.env.VUE_APP_CLUB_GLOBAL_BUSINESS
  const newsLink = process.env.VUE_APP_CLUB_GLOBAL_NEWS

  let mobileOpen = ref(false);
  
  router.afterEach(() => {
    mobileOpen.value = false
  })
</script>
