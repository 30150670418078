<template>
  <div class="bg-white px-4 pt-8 pb-24">
    <div class="lg:container lg:mx-auto p-4">
      <div class="lg:flex">
        <div class="lg:w-1/4">
          <router-link :to="{ name: 'home' }" class="flex font-semibold items-center ">
            <img src="@/icons/logo.svg" alt="Club Global Logo" class="w-12 mr-4">
            Club Global
          </router-link>
        </div>

        <div class="lg:grow mt-8 lg:mt-0">
          <div class="lg:grid lg:grid-cols-4">
            <div class="space-y-4">
              <h3 class="font-bold">Download Club Global</h3>
              <p><a href="https://apps.apple.com/gb/app/club-global/id1343275117">iOS App</a></p>
              <p><a href="https://play.google.com/store/apps/details?id=uk.co.imageplus.clubglobal&hl=en&gl=US">Android App</a></p>
              <p><a href="https://apps.apple.com/gb/app/club-global-ticket-scanner/id1634564084">iOS Ticket Scanner</a></p>
              <p><a href="https://play.google.com/store/apps/details?id=uk.co.imageplus.clubglobal.ticketscanner">Android Ticket Scanner</a></p>
            </div>

            <!-- <div class="space-y-4 mt-4 lg:mt-0">
              <h3 class="font-bold">Working with us</h3>
              <p><a href="#">Brands</a></p>
              <p><a href="#">Influencers and Reps</a></p>
              <p><a href="#">Venue Managers</a></p>
            </div>

            <div class="space-y-4 mt-4 lg:mt-0">
              <h3 class="font-bold">About us</h3>
              <p><a href="#">Stripe</a></p>
              <p><a href="#">About Club Global</a></p>
              <p><a href="#">Venue Managers</a></p>
            </div> -->

            <div class="space-y-4 mt-4 lg:mt-0">
              <h3 class="font-bold">Follow</h3>
              <p><a href="https://www.instagram.com/clubglobalofficial/?hl=en">Instagram</a></p>
              <p><a href="https://www.facebook.com/Clubglobalapp/">Facebook</a></p>
            </div>
          </div>

          <hr class="my-8 border-grey-dark">

          <div class="flex">
            <div class="lg:flex">
              <p><a href="https://blog.clubglobal.com/terms-and-conditions/">Terms and Conditions</a></p>
              <p class="lg:ml-12 mt-4 lg:mt-0"><a href="https://blog.clubglobal.com/privacy-policy/">Privacy Policy</a></p>
              <p class="lg:ml-12 mt-4 lg:mt-0"><a href="javascript:void(0)" onclick="CookieControl.open()">Cookie Consent</a></p>
            </div>
            
            <p class="ml-auto">&copy; Copyright {{ (new Date).getFullYear() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>