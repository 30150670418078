<template>
  <div class="bg-cover bg-black bg-no-repeat bg-center lg:flex lg:items-center justify-center text-white h-[320px] lg:h-[557px]" style="background-image: url('images/Taste-201.JPG')">
    <div class="text-center font-bold pt-8 lg:pt-0 px-4 lg:-mt-20 bg-black/75 rounded-2xl">
      <h1 class="text-xxl px-28 lg:text-[45px] lg:leading-[54px]">Discover, Book &amp; Experience</h1>
      <h2 class="text-xl lg:text-xxl pt-3">Find tickets, venues and events</h2>

      <form @submit.prevent="search" class="py-2 relative w-full lg:w-3/4 mt-4 lg:mx-auto">
        <img src="@/icons/search.svg" alt="Search" class="absolute left-0 top-0 mt-7 ml-5 pointer-events-none">
        <input type="text" v-model.trim="term" placeholder="Search" class="text-black border border-light-grey rounded-xl p-4 pl-14 w-full">
      </form>
    </div>
  </div>

  <spotlight-slider v-for="deck in firstShelf" :key="deck" :deck="deck" class="lg:pl-12"/>
<!--  <item-slider v-for="deck in firstTwoShelves" :key="deck" :deck="deck" class="lg:pl-12"/>-->
  <get-the-app />
  <item-slider v-for="deck in shelves" :key="deck" :deck="deck" class="lg:pl-12"/>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue'
  import axios from 'axios'
  import { useRouter } from 'vue-router'

  import ItemSlider from '@/components/ItemSlider'
  import GetTheApp from '@/components/GetTheApp'
  import SpotlightSlider from "@/components/SpotlightSlider";

  const router = useRouter()

  const spotlight = ref([])

  const term = ref('')

  const firstShelf = computed(() => {
    return spotlight.value.slice(0,2)
  })

  const shelves = computed(() => {
    return spotlight.value.slice(2)
  })

  const search = () => {
    if (!term.value) return
    router.push({ name: 'search', query: { q: term.value } })
  }

  onMounted(async () => {
    const response = await axios.get('spotlight')
    spotlight.value = response.data
  })
</script>
