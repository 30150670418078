<template>
  <router-link :to="link" class="block w-full">

    <div class="text-left rounded-xl h-[100%]" >
      <div v-if="props.type == 0" class="h-[65%]">
        <img class="aspect-square object-cover rounded-xl h-[100%] w-[100%]" :src="props.item.image" :alt="props.item.title + ' logo'">
      </div>
      <div v-else-if="props.type == 2" class="h-[auto]">
        <img class="aspect-square rounded-xl object-cover h-[100%] w-[100%]" :src="props.item.image" :alt="props.item.title + ' logo'" >
      </div>
      <div v-else class="h-[auto]">
        <img class="aspect-square rounded-xl object-cover h-[100%] w-[100%]" :src="props.item.image" :alt="props.item.title + ' logo'" >
      </div>
      
      <div class="p-2">
<!--        <h2 :class="{ 'text-xxl': props.type == 0, 'text-xl': props.type != 0, 'font-bold': props.type == 0 }">{{ props.item.title }}</h2>-->
        <h2 class="text-base sm:text-lg font-bold">{{props.item.title}}</h2>
        <div class="flex">
          <div>
<!--            <p :class="{ 'text-base': props.type == 0, 'font-bold': props.type != 0, 'text-secondary': props.type != 0 }">{{ props.item.subtitle }}</p>-->
            <p class="text-base text-secondary">{{props.item.subtitle}}</p>
            <div v-if="props.item.tags && props.item.tags.length > 0">
<!--              <p v-if="props.type == 0" class="inline-block mt-2 rounded-xl bg-purple-lighter text-xs p-2">{{ props.item.tags[0].name }}</p>-->
              <p class="text-base text-purple-lighter font-bold">{{ props.item.tags[0].name }}</p>
            </div>
          </div>

<!--          <div class="ml-auto flex items-end" v-if="props.type != 0">-->
<!--            <button class="ml-4 bg-gradient-to-r from-primary to-secondary px-4 py-2 rounded-xl">View</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  item: Object,
  type: Number,
  isDark: Boolean
})

const link = computed(() => {
  switch (props.item.key.type) {
    default:
      return '/event/' + props.item.key.id
    case 'venue':
      return '/venue/' + props.item.key.slug
    case 'room':
      return '/venue/' + props.item.key.parent.slug + '/room/' + props.item.key.id
    case 'brand':
      return '/brand/' + props.item.key.slug
  }
})

</script>