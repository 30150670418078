<template>
  <navbar/>
  <router-view/>
  <footer-component/>
</template>

<script setup>
  import Navbar from '@/components/Navbar.vue'
  import FooterComponent from '@/components/FooterComponent.vue'

  import { optIn, optOut } from 'vue-gtag'

  // eslint-disable-next-line
  CookieControl.load({
    apiKey: process.env.VUE_APP_COOKIE_API_KEY,
    product: 'PRO',
    acceptBehaviour: 'all',
    branding: {
      removeIcon: true,
    },
    layout: 'POPUP',
    initialState: 'BOX',
    subDomains: true,
    text: {
      thirdPartyTitle: 'Warning: Some cookies require your attention',
      thirdPartyDescription: 'Consent for some third party cookies can not be automatically revoked. Please follow the link below if you want to opt out of them.'
    },
    optionalCookies: [
      {
        name: 'analytics',
        label: 'Analytical Cookies',
        description: 'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
        cookies: ['_ga', '_gid', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv'],
        vendors: [{
          name: 'Google',
          url: 'https://policies.google.com/technologies/types?hl=en-US',
          description: 'Google Analytics'
        }],
        onAccept: () => optIn(),
        onRevoke: () => optOut()
      },
    ]
  })
</script>