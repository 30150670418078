import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from '../views/NotFoundView.vue'

import { useUserStore } from '@/store/user'
import { useCheckoutStore } from '@/store/checkout'

const routes = [
  {
    path: '/:pathMatch(.*)',
    component: NotFoundView,
    meta: { title: 'Not Found' }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[login].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: { title: 'Login', noAuthentication: true }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
    meta: { title: 'Register', noAuthentication: true }
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import(/* webpackChunkName: "forgot_password" */ '../views/ForgotPasswordView.vue'),
    meta: { title: 'Forgot Password', noAuthentication: true }
  },
  {
    path: '/reset_password/:token/:email',
    name: 'reset_password',
    component: () => import(/* webpackChunkName: "reset_password" */ '../views/ResetPasswordView.vue'),
    meta: { title: 'Reset Password', noAuthentication: true }
  },
  {
    path: '/unsubscribe/:email',
    name: 'unsubscribe_marketing',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/UnsubscribeView.vue'),
    meta: { title: 'Unsubscribe' }
  },
  {
    path: '/unsubscribe/:entity/:slug/:email',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/UnsubscribeView.vue'),
    meta: { title: 'Unsubscribe' }
  },

  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue'),
    meta: { title: 'Map' }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/EventsView.vue'),
    meta: { title: 'Events' }
  },
  {
    path: '/event/:id',
    name: 'event',
    component: () => import(/* webpackChunkName: "event" */ '../views/EventView.vue'),
    meta: { title: 'Event' }
  },
  {
    path: '/event/:eventId/table/:id',
    name: 'table',
    component: () => import(/* webbackChunkName: "table" */ '../views/TableView.vue'),
    meta: { title: 'Table' }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue'),
    meta: { title: 'Search' },
    props: route => ({ query: route.query.q })
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsView.vue'),
    meta: { title: 'Locations' }
  },
  {
    path: '/location/:slug',
    name: 'location',
    component: () => import(/* webpackChunkName: "location" */ '../views/LocationView.vue'),
    meta: { title: 'Location' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webbackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: { title: 'Profile', authentication: true }
  },
  {
    path: '/profile/edit',
    name: 'edit_profile',
    component: () => import(/* webbackChunkName: "edit_profile" */ '../views/EditProfileView.vue'),
    meta: { title: 'Edit Profile', authentication: true }
  },
  {
    path: '/venue/:slug',
    name: 'venue',
    component: () => import(/* webbackChunkName: "venue" */ '../views/VenueView.vue'),
    meta: { title: 'Venue' }
  },
  {
    path: '/venue/:slug/hire',
    name: 'hire',
    component: () => import(/* webbackChunkName: "hire" */ '../views/HireView.vue'),
    meta: { title: 'Private Event Hire' }
  },
  {
    path: '/venue/:slug/room/:id',
    name: 'room',
    component: () => import(/* webbackChunkName: "room" */ '../views/RoomView.vue'),
    meta: { title: 'Room' }
  },
  {
    path: '/brand/:slug',
    name: 'brand',
    component: () => import(/* webbackChunkName: "brand" */ '../views/BrandView.vue'),
    meta: { title: 'Brand' }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webbackChunkName: "checkout" */ '../views/CheckoutView.vue'),
    meta: { title: 'Checkout', authentication: true }
  },
  {
    path: '/checkout_finish',
    name: 'checkout_finish',
    component: () => import(/* webbackChunkName: "checkout_finish" */ '../views/CheckoutFinishView.vue'),
    meta: { title: 'Order Complete', authentication: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { top: 0 }
  }
})

router.beforeEach(to => {
  if (to.query.click) {
    const checkoutStore = useCheckoutStore()
    checkoutStore.setClick(to.query.click)
  }
  if (to.query.ad_event) {
    const checkoutStore = useCheckoutStore()
    checkoutStore.setAdEvent(to.query.ad_event)
  }
  if (to.query.fbclid) {
    const checkoutStore = useCheckoutStore()
    checkoutStore.setFBClick(to.query.fbclid)
  }
  // In case we navigated from within a modal
  document.body.classList.remove('modal-open')
  document.body.style.top = ''

  const userStore = useUserStore()

  if (userStore.redirectTo && userStore.isAuthenticated()) {
    const redirect = userStore.redirectTo
    userStore.redirectTo = null
    return redirect
  }

  if (to.meta.authentication && !userStore.isAuthenticated()) {
    userStore.redirectTo = to
    return { name: 'login' }
  }

  if (to.meta.noAuthentication && userStore.isAuthenticated()) {
    return { name: 'profile' }
  }
})

router.afterEach(to => {
  document.title = `${to.meta.title} | Club Global`
})

router.beforeEach(async (to, from) => {
  if (to.name !== 'checkout') {
    const checkoutStore = useCheckoutStore()
    const userStore = useUserStore()
    if ((from.name === 'login' || from.name === 'register') && checkoutStore.order !== null && userStore.isAuthenticated()) {
      await checkoutStore.fullTicketProcess()
      return {name: 'checkout'}
    } else if (from.name ==='login' || from.name === 'register' && checkoutStore.order !== null && !userStore.isAuthenticated()) {
      checkoutStore.order = null
    }
  }
})

export default router
