import { defineStore } from 'pinia'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    from: {
      name: null,
      params: null
    },
    tickets: null,
    table: null,
    client_secret: null,
    order_id: null,
    parent: null,
    subscribed: null,
    allowMarketing: null,
    payment_required: false,
    save_card: false,
    click: null,
    ad_event: null,
    fbclid: null,
    timerLength: 0,
    awaitingRequest: false,
    order: null,
    ordered_from: null,
    is_table: false
  }),

  persist: true,
  actions: {
    setClick(click) {
      this.click = click
    },
    setAdEvent(ad_event) {
      this.ad_event = ad_event
    },
    setFBClick(fbclid) {
      this.fbclid = fbclid
    },
    setFrom(from) {
      this.from = from
    },

    addTickets(data) {
      this.table = null
      this.tickets = data
    },

    addTable(data) {
      this.tickets = null
      this.table = data
    },

    async getTicketHoldingTime() {
      const response = await axios.get('settings')
      return response.data.checkout_countdown_timer_seconds * 1000
    },

    setParent(parent) {
      this.parent = parent
    },

    setMarketingData(subscribed, allowMarketing) {
      this.subscribed = subscribed
      this.allowMarketing = allowMarketing
    },

    async reserveTickets(data, date, eventId) {
      this.awaitingRequest = true
      const response = await axios.post('store/tickets', {
        uuid: uuidv4(),
        tickets: data.map(ticket => {
          return {
            id: ticket.id,
            quantity: ticket.quantity
          }
        }),
        date: date ?? null,
        event: eventId ?? null,
        click: this.click ?? null,
        facebook_ad_event: this.ad_event ?? null,
        facebook_clid: this.fbclid ?? null
      })

      this.awaitingRequest = false
      this.order_id = response.data.order_id
      this.client_secret = response.data.client_secret
      this.payment_required = response.data.payment_required
    },

    async reserveTable(data, eventId) {
      this.awaitingRequest = true;
      const response = await axios.post('store/tables', {
        uuid: uuidv4(),
        tables: [
          {
            event_id: eventId ?? null,
            table_id: data.id,
            timestamp: data.selected_date.date
          }
        ],
        event: eventId ?? null,
        click: this.click ?? null,
        facebook_ad_event: this.ad_event ?? null,
        facebook_clid: this.fbclid ?? null
      })
      this.awaitingRequest = false;
      this.order_id = response.data.order_id
      this.client_secret = response.data.client_secret
      this.payment_required = response.data.payment_required
    },

    async getCards() {
      const response = await axios.get('customer/user/cards')
      return response.data
    },

    async pay() {
      let data = { save_card: this.save_card }
      if (this.subscribed !== null) {
        data.subscribed = this.subscribed
      }
      if (this.allowMarketing !== null) {
        data.allow_marketing = this.allowMarketing
      }

      return axios.post(`customer/orders/${this.order_id}/pay`, data)
    },

    async releaseOrder() {
      await axios.post(`orders/${this.order_id}/trash`)
      this.reset()
    },

    async fullTicketProcess() {
      if (this.is_table) {
        if (this.from.name === 'event') {
          await this.reserveTable(this.order, this.event_id)
        } else if (this.from.name === 'venue') {
          await this.reserveTable(this.order)
        }
        this.addTable(this.order)
      } else {
        if (this.from.name === 'event') {
          await this.reserveTickets(this.order, null, this.event_id)
        } else if (this.from.name === 'venue') {
          await this.reserveTickets(this.order, Math.floor(this.order[0].dates[0] / 1000))
        }
        this.addTickets(this.order)
      }
    },

    reset() {
      this.from = null
      this.tickets = null
      this.table = null
      this.client_secret = null
      this.order_id = null
      this.parent = null
      this.subscribed = null
      this.allowMarketing = null
      this.payment_required = false
      this.save_card = false
      this.click = null
      this.ad_event = null
      this.fbclid = null
      this.order = null
      this.event_id = null
      this.is_table = false
    }
  }
})
