import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
  state: () => ({
    redirectTo: null,
    token: null,
    name: null,
    email: null
  }),
  persist: {
    afterRestore: (context) => {
      if (context.store.token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.store.token
      }
    }
  },
  actions: {
    isAuthenticated() {
      return this.token !== null
    },

    handleLogin(response) {
      this.token = response.data.access_token
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
    },

    async login(email, password) {
      try {
        const response = await axios.post('oauth/token', {
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          username: email,
          password: password
        }, { baseURL: process.env.VUE_APP_API_BASE_URL })
        this.handleLogin(response)
        await this.refreshUser()
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    async appleLogin() {
      let id_token = ''
      let tempName = null;
      try {
        // eslint-disable-next-line
        const appleResponse = await AppleID.auth.signIn()
        id_token = appleResponse.authorization.id_token
        try{
          tempName = appleResponse.user.name.firstName + " " + appleResponse.user.name.lastName;
        }
        catch(err){
          console.log('_');
        }

      } catch (err) {
        throw new Error(err.error)
      }
      try {
        const response = await axios.post('oauth/token', {
          grant_type: 'social',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          provider: 'sign-in-with-apple',
          access_token: id_token
        }, { baseURL: process.env.VUE_APP_API_BASE_URL })
        this.handleLogin(response)
        if(tempName != null)
        {
          try{
            this.edit({
              name: tempName
            });
          }
          catch (err) {
            console.log('__');
          }
        }

        await this.refreshUser()
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    async facebookLogin() {
      return new Promise(resolve => {
        // eslint-disable-next-line
        FB.login(fbResponse => {
          if (!fbResponse.authResponse) {
            throw new Error(fbResponse.status)
          }

          axios.post('oauth/token', {
            grant_type: 'social',
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            provider: 'facebook',
            access_token: fbResponse.authResponse.accessToken
          }, { baseURL: process.env.VUE_APP_API_BASE_URL }).then(async response => {
            this.handleLogin(response)

            await this.refreshUser()

            resolve()
          }).catch(err => {
            throw new Error(err.response.data.message)
          })
        }, { scope: 'email' })
      })
    },

    async googleLogin(credential) {
      try {
        const response = await axios.post('oauth/token', {
          grant_type: 'social',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          provider: 'google',
          access_token: credential
        }, { baseURL: process.env.VUE_APP_API_BASE_URL })
        this.handleLogin(response)

        await this.refreshUser()
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    logout() {
      this.token = null
      this.name = null
      this.email = null

      axios.defaults.headers.common['Authorization'] = null
    },

    async forgotPassword(email) {
      await axios.post('reset-password/request', { email: email })
    },

    async resetPassword(data) {
      try {
        await axios.post('reset-password/change', data)
      } catch (err) {
        throw new Error(err.response.data.errors.map(error => error.token || error.email))
      }
    },
    async unsubscribe(data) {
      let url = ''
      if(data.entity){
        url = data.entity + '/' + data.slug + '/' + data.email
      } else {
        url = data.email
      }
      try {
        await axios.post('unsubscribe/' + url )
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    async register(data) {
      try {
        await axios.post('customer/users/register/email', data)
        await this.login(data.email, data.password)
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    async edit(data) {
      try {
        await axios.post('user', data)
      } catch (err) {
        throw new Error(err.response.data.message)
      }
    },

    async refreshUser() {
      const response = await axios.get('user')
      this.name = response.data.name
      this.email = response.data.email
    }
  }
})
