import { createApp } from 'vue'
import App from './App'
import router from './router'

import './assets/tailwind.css'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import VueGoogleMaps from '@fawmi/vue-google-maps'

import VueGtag from 'vue-gtag'

createApp(App)
  .use(pinia)
  .use(router)
  .use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY }})
  .use(VueGtag, { config: { id: process.env.VUE_APP_GOOGLE_TRACKING_ID }, enabled: false }, router)
  .mount('#app')

import { useUserStore } from '@/store/user'
const userStore = useUserStore()

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + 'api/'
axios.defaults.headers.common['Device-Type'] = 'web'
axios.defaults.headers.common['Device-Version'] = process.env.VUE_APP_VERSION
axios.interceptors.response.use(response => response, error => {
  if (error.response.status == 401) {
    userStore.logout()
    userStore.redirectTo = router.currentRoute.value
    if(router.currentRoute.value.name  != 'reset_password'){
      router.replace({ name: 'login' })
    }
  }

  return Promise.reject(error)
})