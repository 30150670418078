<template>
  <div class="bg-gradient-to-r from-primary to-secondary text-white">
    <div class="lg:container lg:mx-auto flex px-4 py-12 lg:items-center lg:flex-row flex-col">
      <div class="text-lg lg:w-1/2">
        <h1 class="text-xxl font-bold">Discover, book and experience.</h1>

        <p class="mt-8">Club Global is your personal nightlife guide.</p>
        <p>Our App gives you the power to instantly book tickets and table service at the best venues and events.</p>

        <div class="flex mt-8 space-x-4 justify-center lg:justify-start">
          <a href="https://play.google.com/store/apps/details?id=uk.co.imageplus.clubglobal&hl=en&gl=US"><img src="@/icons/google_play.svg" alt="Google Play"></a>
          <a href="https://apps.apple.com/gb/app/club-global/id1343275117"><img src="@/icons/apple_store.svg" alt="App Store"></a>
        </div>
      </div>

      <div class="mx-auto lg:mx-0 mt-8 lg:mt-0 lg:w-1/2 flex justify-center">
        <img src="images/devices.png" alt="App" class="max-h-[400px]">
      </div>
    </div>
  </div>
</template>